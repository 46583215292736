import * as React from 'react';

interface IChariotResultsContentProps {

}

class ChariotResultsContent extends React.PureComponent<IChariotResultsContentProps> {
    render() {
        return (
            <>
                <h4>
                    Results of the CHARIOT clinical trial
                </h4>
                <p>
                    The CHARIOT clinical trial investigated if using two immunotherapy drugs (nivolumab and ipilimumab) together with standard chemotherapy (paclitaxel) before surgery, was safe and effective and could stimulate the body’s immune system to kill the cancer cells. And, if continuing treatment with one of these drugs (nivolumab) after surgery can keep the immune system active to eradicate any residual cancer cells.
                </p>
                <p>
                    This was a world-first Australian clinical trial developed by Breast Cancer Trials (BCT) researchers, which recruited 34 patients at eight participating institutions throughout Australia.
                </p>
                <p>
                    CHARIOT was open to both women and men diagnosed with triple negative early breast cancer demonstrated to be resistant to traditional neoadjuvant chemotherapy treatments.
                </p>
                <p>
                    Primary results of the CHARIOT clinical trial were presented at the American Society of Clinical Oncology (ASCO) international conference in 2022. Those results showed the addition of nivolumab and ipilimumab resulted in a promising response rate with 24% of participants achieving a complete disappearance of their cancer within the breast and lymph nodes by the time of surgery but longer term follow up was needed to determine the overall effectiveness of this treatment.
                </p>
            </>
        );
    }
}

export default ChariotResultsContent;