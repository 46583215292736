import * as React from 'react';
import { FC, useRef } from 'react';
import "../../../assets/fontawesome/css/all.css";
import './TimelineElement.scss';

import VisibilitySensor from 'react-visibility-sensor';

import classNames from 'classnames';
import { useInViewport } from 'react-in-viewport';

interface ITimelineElementProps {
    className: string;
}

const TimelineElement: FC<ITimelineElementProps> = ({className, children}) => {
    
    const [visible, setVisible] = React.useState<boolean>(false);

    const container = document.getElementById('app'); 

    const myRef = useRef<HTMLDivElement | null>(null);
    const {
        inViewport
    } = useInViewport(
        myRef,
        {threshold: 0},
        { disconnectOnLeave: false,  }
    );

    React.useEffect(() => {
        if (inViewport) {
            setVisible(true);
        }
    }, [inViewport])

    return (
        <div
            className={classNames(className, 'vertical-timeline-element', {
                'vertical-timeline-element--no-children': children === '',
            })}
        >
            <div ref={myRef}
                className={`vertical-timeline-element-content ${
                    visible ? 'bounce-in' : 'is-hidden'
                    }`}
            >
                {children}
            </div>
            <span
                className={`vertical-timeline-element-icon ${
                    visible ? 'bounce-in' : 'is-hidden'
                    }`}
            >
            </span>
        </div>
    );
}

export default TimelineElement;