import * as React from 'react';
import { FC } from 'react';

interface ITimelineYearProps {
    year: string
}

const TimelineYear: FC<ITimelineYearProps> = ({year, children}) => {
    return (
        <div className="vertical-timeline-year">
            <div className="vertical-timeline-year-arrow">
                <svg viewBox="0 0 105 34">
                    <polygon points="0,0 105,0 0,34 0,0" className="vertical-timeline-year-pointer" />
                </svg>
            </div>
            <div className="vertical-timeline-year-heading">
                <h2>{year}</h2>
            </div>
            {children}
        </div>
    );
}

export default TimelineYear;