import * as React from 'react';

interface IFellowshipBeginsContentProps {

}

class FellowshipBeginsContent extends React.PureComponent<IFellowshipBeginsContentProps> {
    render() {
        return (
            <>
                <h4>
                    The first year of the Clinical Fellowship Program begins
                </h4>
                <p>
                    The Program is aimed at early career researchers, who have a high-level interest in clinical research and qualifications in the disciplines of medical oncology, pathology, psychology and other supportive care specialties, radiation oncology, radiology or surgery.
                </p>
                <p>
                    Fellows work on projects that are directly relevant to BCT that potentially involve:
                </p>
                <ul>
                    <li>Research areas of future trials including background and pilot work</li>
                    <li>Work on existing or future BCT clinical trials</li>
                    <li>Further analysis of data from existing trials</li>
                    <li>Participation in trial meetings to drive the research as directed by our strategy</li>
                </ul>
            </>
        );
    }
}

export default FellowshipBeginsContent;