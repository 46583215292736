import TimelineVideo from "../../../../timeline/TimelineVideo/TimelineVideo";

const VideoPreview = require('../../../../../assets/img/timeline/modal/2019-BRCAP.jpg');

const BRCAPContent = () => {
    return (
        <>
            <TimelineVideo videoId="GU9LD9ZU1IU" imagePreview={VideoPreview} />
            <h4>
                A new world-first clinical trial opens called BRCA-P, which aims to prevent breast cancer in women with a BRCA1 gene mutation
            </h4>
        </>
    );
}

export default BRCAPContent;