import * as React from 'react';

import './TimelineCircleImage.scss';
const classNames = require('classnames');

interface ITimelineCircleImageProps {
    image: any;
    className?: string;

}

class TimelineCircleImage extends React.PureComponent<ITimelineCircleImageProps> {
    render() {
        const { image, className } = this.props;

        return (
            <div className={classNames("vertical-timeline-circle-image", className)}>
                <div>
                    <div>
                        <img src={image} />
                    </div>
                </div>
            </div>
        );
    }
}

export default TimelineCircleImage;