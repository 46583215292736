import * as React from 'react';
//import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import VerticalTimeline from '../timeline/Timeline/Timeline';
import VerticalTimelineElement from '../timeline/TimelineElement/TimelineElement';
import VerticalTimelineYear from '../timeline/TimelineYear/TimelineYear';
import VerticalTimelineSection from '../timeline/TimelineSection/TimelineSection';

import './Timeline.scss';


import ExtractContent1970s from './modalContent/1970s/ExtractContent/AsyncExtractContent'
import EstablishmentContent1978 from './modalContent/1978/EstablishmentContent/AsyncEstablishmentContent'
import FirstTrialContent1978 from './modalContent/1978/FirstTrialContent/AsyncFirstTrialContent'
import IdentificationContent1984 from './modalContent/1984/IdentificationContent/AsyncIdentificationContent'
import RelocateContent1987 from './modalContent/1987/RelocateContent/AsyncRelocateContent'
import TrialResultsContent1987 from './modalContent/1987/TrialResultsContent/AsyncTrialResultsContent'
import BreastScreeningContent1991 from './modalContent/1991/BreastScreeningContent/AsyncBreastScreeningContent'
import IbisContent1992 from './modalContent/1992/IbisContent/AsyncIbisContent'
import SacContent1992 from './modalContent/1992/SacContent/AsyncSacContent'
import BciaContent1994 from './modalContent/1994/BciaContent/AsyncBciaContent'
import ConferenceContent1994 from './modalContent/1994/ConferenceContent/AsyncConferenceContent'
import IdentificationContent1994 from './modalContent/1994/IdentificationContent/AsyncIdentificationContent'
import PartnershipContent1995 from './modalContent/1995/PartnershipContent/AsyncPartnershipContent'
import BiopsyContent1996 from './modalContent/1996/BiopsyContent/AsyncBiopsyContent'
import GolfContent1996 from './modalContent/1996/GolfContent/AsyncGolfContent'
import PartnershipContent1996 from './modalContent/1996/PartnershipContent/AsyncPartnershipContent'
import RaceContent1996 from './modalContent/1996/RaceContent/AsyncRaceContent'
import SupportersContent1996 from './modalContent/1996/SupportersContent/AsyncSupportersContent'
import BequestContent1998 from './modalContent/1998/BequestContent/AsyncBequestContent'
import CapContent1998 from './modalContent/1998/CapContent/AsyncCapContent'
import ChemotherapyContent1998 from './modalContent/1998/ChemotherapyContent/AsyncChemotherapyContent'
import NewsletterContent1998 from './modalContent/1998/NewsletterContent/AsyncNewsletterContent'
import TrastuzumabContent1999 from './modalContent/1999/TrastuzumabContent/AsyncTrastuzumabContent'
import MothersDayContent2000 from './modalContent/2000/MothersDayContent/AsyncMothersDayContent'
import RgpContent2000 from './modalContent/2000/RgpContent/AsyncRgpContent'
import AromataseContent2002 from './modalContent/2002/AromataseContent/AsyncAromataseContent'
import ImpactContent2002 from './modalContent/2002/ImpactContent/AsyncImpactContent'
import GenomeContent2003 from './modalContent/2003/GenomeContent/AsyncGenomeContent'
import TreatmentContent2004 from './modalContent/2004/TreatmentContent/AsyncTreatmentContent'
import HeraContent2005 from './modalContent/2005/HeraContent/AsyncHeraContent'
import TrialResultContent2006 from './modalContent/2006/TrialResultContent/AsyncTrialResultContent'
import TrialResultsContent2008 from './modalContent/2008/TrialResultsContent/AsyncTrialResultsContent'
import LogoContent2011 from './modalContent/2011/LogoContent/AsyncLogoContent'
import TrialResultsContent2011 from './modalContent/2011/TrialResultsContent/AsyncTrialResultsContent'
import TrialResultsContent2012 from './modalContent/2012/TrialResultsContent/AsyncTrialResultsContent'
import PoemsResultsContent2014 from './modalContent/2014/PoemsResultsContent/AsyncPoemsResultsContent'
import PortfolioContent2014 from './modalContent/2014/PortfolioContent/AsyncPortfolioContent'
import SoftResultsContent2014 from './modalContent/2014/SoftResultsContent/AsyncSoftResultsContent'
import AromataseContent2015 from './modalContent/2015/AromataseContent/AsyncAromataseContent'
import InhibitorsContent2015 from './modalContent/2015/InhibitorsContent/AsyncInhibitorsContent'
import TrialResultsContent2016 from './modalContent/2016/TrialResultsContent/AsyncTrialResultsContent'
import RelocateContent2017 from './modalContent/2017/RelocateContent/AsyncRelocateContent'
import ExpertContent2017 from './modalContent/2017/ExpertContent/AsyncExpertContent'
import JohnForbesContent2017 from './modalContent/2017/JohnForbesContent/AsyncJohnForbesContent'
import NewNameContent2017 from './modalContent/2017/NewNameContent/AsyncNewNameContent'
import AnniversaryContent2018 from './modalContent/2018/AnniversaryContent/AsyncAnniversaryContent'
import TrialResultsContent2018 from './modalContent/2018/TrialResultsContent/AsyncTrialResultsContent'
import TwoTrialsContent2018 from './modalContent/2018/TwoTrialsContent/AsyncTwoTrialsContent'
import BRCAPContent from './modalContent/2019/BRCAP/BRCAPContent'
import FellowshipBeginsContent from './modalContent/2021/FellowshipBeginsContent';
import ResultsOlympiaContent from './modalContent/2021/ResultsOlympiaContent';
import LaunchNeoadjuvantContent from './modalContent/2021/LaunchNeoadjuvantContent';
import ResearchReportImpactContent from './modalContent/2022/ResearchReportImpactContent';
import NicholasZdenkowskiContent from './modalContent/2022/NicholasZdenkowskiContent/NicholasZdenkowskiContent';
import ChariotResultsContent from './modalContent/2022/ChariotResultsContent';
import ProspectResultsContent from './modalContent/2022/ProspectResultsContent';
import NeonResultsContent from './modalContent/2023/NeonResultsContent';
import { FC } from 'react';

const BRCAP2019 = require('../../assets/img/timeline/2019-Geoff-Lindeman.jpg');
const Bct2018 = require('../../assets/img/timeline/2018-BCT-40th-Annivserary.jpg');
const LeslieGilham2018 = require('../../assets/img/timeline/2018-Leslie-Gilham.jpg');
const Awhd2018 = require('../../assets/img/timeline/2018-AWHD.jpg');
const PrueFrancis2017 = require('../../assets/img/timeline/2017-Prue-Francis.jpg');
const JohnForbes2017 = require('../../assets/img/timeline/2017-John-Forbes.jpg');
const BruceMann2017 = require('../../assets/img/timeline/2017-bruce-man.jpg');
const BctOffice2017 = require('../../assets/img/timeline/2017-BCT-Office.jpg');
const PalomaResults2016 = require('../../assets/img/timeline/2016-PALOMA-results.jpg');
const StephenAckland2015 = require('../../assets/img/timeline/2015-Stephen-Ackland.jpg');
const LisaWilkinson2013 = require('../../assets/img/timeline/2013-Lisa-Wilkinson.jpg');
const FranBoyle2012 = require('../../assets/img/timeline/2012-Fran-Boyle.jpg');
const AnzbctgLogo2011 = require('../../assets/img/timeline/2011-ANZBCTG-logo.jpg');
const NicholasWilcken2010 = require('../../assets/img/timeline/2010-Nicholas-Wilcken.jpg');
const LeonieYoung2010 = require('../../assets/img/timeline/2010-Leonie-Young.jpg');
const ChrisBath2007 = require('../../assets/img/timeline/2007-Chris-Bath.jpg');
const ImpactAdvocateProgram2006 = require('../../assets/img/timeline/2006-IMPACT-Advocate-Program.jpg');
const FranBoyle2006 = require('../../assets/img/timeline/2006-Fran-Boyle.jpg');
const JacquieChirgwin2005 = require('../../assets/img/timeline/2005-Jacquie-Chirgwin.jpg');
const ImpactWorkshop2002 = require('../../assets/img/timeline/2002-IMPACT-Workshop.jpg');
const JenniferKeyte1999 = require('../../assets/img/timeline/1999-Jennifer-Keyte.jpg');
const Awhd1999 = require('../../assets/img/timeline/1999-AWHD.jpg');
const FirstNewsletter1998 = require('../../assets/img/timeline/1998-First-Newsletter.jpg');
const ConsumerAdvisoryPanel1998 = require('../../assets/img/timeline/1998-Consumer-Advisory-Panel.jpg');
const RaceForResearch1996 = require('../../assets/img/timeline/1996-Race-for-Research.jpg');
const AvonPartnership1996 = require('../../assets/img/timeline/1996-Avon-Partnership.jpg');
const AnnualGolfDay1996 = require('../../assets/img/timeline/1996-Annual-Golf-Day.jpg');
const RaymondSnyder1995 = require('../../assets/img/timeline/1995-Raymond-Snyder.jpg');
const CommonwealthBankPartnership1995 = require('../../assets/img/timeline/1995-Commonweath-Bank-Partnership.jpg');
const ScientificAdvisoryCommittee1992 = require('../../assets/img/timeline/1992-Scientific-Advisory-Committee.jpg');
const NewcastleRelocation1987 = require('../../assets/img/timeline/1987-Newcastle-Relocation.jpg');
const JohnForbes1978 = require('../../assets/img/timeline/1978-John-Forbes.jpg');
const ShereneLoi2018 = require('../../assets/img/timeline/2018-Sherene-Loi.jpg');


const SoozySmith2010 = require('../../assets/img/timeline/2010-Soozy-Smith.jpg');
const BruceMann2021 = require('../../assets/img/timeline/2021-Bruce-Mann.jpg');
const SunilLakhani2021 = require('../../assets/img/timeline/2021-Sunil-Lakhani.jpg');
const NicholasZdenkowski2022 = require('../../assets/img/timeline/2022-Nicholas-Zdenkowski.jpg');
const Diary2023 = require('../../assets/img/timeline/2023-diary.jpg');
const KarenPrice2024 = require('../../assets/img/timeline/2024-Karen-Price.jpg');

const Timeline: FC = () => {    
    return (
        <VerticalTimeline>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-5"

            >
                <VerticalTimelineYear
                    year="2024"
                >
                    <VerticalTimelineSection
                        image={KarenPrice2024}
                    >
                        Karen Price is appointed CEO of Breast Cancer Trials
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                    >
                        The Board approves a new Vision for BCT of No More Lives Cut Short as part of the 2024-2029 Strategic Plan
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                    >
                        It is estimated that in 2024, 20,458 women and 217 men will be diagnosed with breast cancer in Australia, and more than 3,500 women and 25 men will be diagnosed in New Zealand.
                    </VerticalTimelineSection>

                </VerticalTimelineYear>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--color-1"

            >
                <VerticalTimelineYear
                    year="2023"
                >
                    <VerticalTimelineSection
                    >
                        We measured our carbon footprint and produced our first <a href='https://www.breastcancertrials.org.au/reports/carbon-management-report/'>Carbon Management Report</a>
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<NeonResultsContent />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        Results of the Neo-N clinical trial
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                    >
                        Statement in support of the <a href='https://www.breastcancertrials.org.au/news/statements/statement-supporting-the-voice-to-parliament/'>Voice to Parliament</a>
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={Diary2023}
                    >
                        25<sup>th</sup> anniversary of the <a href='https://www.breastcancertrials.org.au/australian-womens-health-diary/'>Australian Women’s Health Diary</a>
                    </VerticalTimelineSection>

                </VerticalTimelineYear>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--color-2"

            >
                <VerticalTimelineYear
                    year="2022"
                >
                    <VerticalTimelineSection
                        modalContent={<ResearchReportImpactContent />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        QUT’s Australian Centre for Philanthropy and Nonprofit Studies conducts an independent report into the impact of Breast Cancer Trials research
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                    >
                        Launch of the <a href="https://www.breastcancertrials.org.au/research/research-strategy/">2022-2026 Research Strategy</a>
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={NicholasZdenkowski2022}
                        modalContent={<NicholasZdenkowskiContent />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Dr Nicholas Zdenkowski is appointed Chair of the Scientific Advisory Committee 
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<ChariotResultsContent />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Results of the CHARIOT clinical trial
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<ProspectResultsContent />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Results of the PROSPECT clinical trial
                    </VerticalTimelineSection>





                </VerticalTimelineYear>
            </VerticalTimelineElement>



            <VerticalTimelineElement
                className="vertical-timeline-element--color-3"
            >
                <VerticalTimelineYear
                    year="2021"
                >
                    <VerticalTimelineSection
                    >
                        On World Cancer Day, 4 February 2021, the World Health Organisation (WHO) revealed that breast cancer had overtaken lung cancer to become the most commonly diagnosed cancer globally. Breast cancer now accounts for nearly 12% of new cancer  cases each year, with around 2.3 million new cases diagnosed worldwide.
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={SunilLakhani2021}
                    >
                        Professor Sunil Lakhani is elected Chair of the Board of Directors 
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={BruceMann2021}
                    >
                        Professor Bruce Mann is appointed BCT’s Director of Research
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<FellowshipBeginsContent />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        The first year of the Clinical Fellowship Program begins
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<ResultsOlympiaContent />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        Results from the OlympiA clinical trial
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<LaunchNeoadjuvantContent />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        Launch of Neoadjuvant Patient Decision Aid
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-4"
            >
                <VerticalTimelineYear
                    year="2020"
                >
                    <VerticalTimelineSection
                    >
                        The EXPERT clinical trial opened its first international site - the National Taiwan University Hospital in East Asia. This is the first Breast Cancer Trials led study that has been opened outside of Australia and New Zealand.  
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                    >
                        More than 16,000 people have participated in our clinical trials from Australia and New Zealand since we first began in 1978
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-5"
            >
                <VerticalTimelineYear
                    year="2019"
                >                            
                    <VerticalTimelineSection                           
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        Breast cancer is estimated to become the most commonly diagnosed cancer in Australia for the first time. It’s estimated 19,371 women and 164 men in Australia will be diagnosed with breast cancer in Australia in 2019. That’s 53 people every day. 1 in 7 women will be diagnosed with breast cancer by the age of 85 in Australia. <a href="https://www.aihw.gov.au/reports/cancer/cancer-data-in-australia/contents/rankings" className="white-a-href">Read More</a>                                                                                   
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={BRCAP2019}
                        modalContent={<BRCAPContent />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        A new world-first clinical trial opens called BRCA-P, which aims to prevent breast cancer in women with a BRCA1 gene mutation
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-1"
            >
                <VerticalTimelineYear
                    year="2018"
                >
                    <VerticalTimelineSection
                        image={Bct2018}
                        modalContent={<AnniversaryContent2018 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        Breast Cancer Trials Celebrates its' 40th anniversary
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={ShereneLoi2018}
                        modalContent={<TwoTrialsContent2018 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        Two new world-first immunotherapy clinical trials commence called DIAmOND and CHARIOT
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<TrialResultsContent2018 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        Results of the TAILORx clinical trial
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={Awhd2018}
                    >
                        20th edition of the Australian Women's Health Diary is produced
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={LeslieGilham2018}
                    >
                        Ms Leslie Gilham becomes the Chair of the Consumer Advisory Panel
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        18,235 PEOPLE will be diagnosed with breast cancer in Australia (18,087 women and 148 men) and more than 3,000 in New Zealand. 1 in 8 women will be diagnosed with breast cancer by the age of 85
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        In its 40th year, Breast Cancer Trials research program incorporated almost 800 researchers across 101 institutions in Australia and New Zealand
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-2"
                
            >
                <VerticalTimelineYear
                    year="2017"
                >
                    <VerticalTimelineSection
                        image={JohnForbes2017}
                        modalContent={<JohnForbesContent2017 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Professor John Forbes AM retires as the BCT Director of Research
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={BctOffice2017}
                        modalContent={<RelocateContent2017 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        The BCT relocates offices
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<NewNameContent2017 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Our new name - Breast Cancer Trials - is launched
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<ExpertContent2017 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        The EXPERT clinical trial commences
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={BruceMann2017}
                    >
                        Professor Bruce Mann becomes the Chair of the Board of Directors
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={PrueFrancis2017}
                    >
                        Associate Professor Prue Francis becomes the Chair of the Scientific Advisory Committee
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        Participation in our clinical trials research program reaches a milestone of 15,000 women
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-3"
                
            >
                <VerticalTimelineYear
                    year="2016"
                >
                    <VerticalTimelineSection
                        image={PalomaResults2016}
                        modalContent={<TrialResultsContent2016 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        PALOMA-2 clinical trial results
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        20th Anniversary of our partnership with Avon Australia and New Zealand
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-4"
                
            >
                <VerticalTimelineYear
                    year="2015"
                >
                    <VerticalTimelineSection
                        modalContent={<InhibitorsContent2015 />}
                        modalClassName={"vertical-timeline-modal--color-4"}
                    >
                        A new class of drugs called CDK inhibitors are introduced 
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={StephenAckland2015}
                    >
                        Professor Stephen Ackland becomes the Chair of the Board of Directors
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<AromataseContent2015 />}
                        modalClassName={"vertical-timeline-modal--color-4"}
                    >
                        Positive research results for aromatase inhibitors
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        20th Anniversary of our partnership with the Commonwealth Bank of Australia
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        20th Anniversary of the golf event ‘Tee off for Breast Cancer Trials’
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-5"
                
            >
                <VerticalTimelineYear
                    year="2014"
                >
                    <VerticalTimelineSection
                        modalContent={<SoftResultsContent2014 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        The results of the SOFT and TEXT clinical trials are published in the New England Journal of Medicine
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<PoemsResultsContent2014 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        The POEMS clinical trial proves the benefits of a new treatment that preserves the fertility of young women during cancer treatment
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<PortfolioContent2014 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        Local therapy trials become a bigger part of the BCT portfolio
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        16,614 women were diagnosed with breast cancer in Australia and more than 3,000 in New Zealand. 1 in 8 women were diagnosed by the age of 85. The five-year survival rate was 90%
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-1"
                
            >
                <VerticalTimelineYear
                    year="2013"
                >
                    <VerticalTimelineSection
                        image={LisaWilkinson2013}
                    >
                        Lisa Wilkinson becomes the Ambassador for  The Australian Women’s Health Diary
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-2"
                
            >
                <VerticalTimelineYear
                    year="2012"
                >
                    <VerticalTimelineSection
                        modalContent={<TrialResultsContent2012 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Results from the ANZ 9602 ATLAS clinical trial are published in The Lancet
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={FranBoyle2012}
                    >
                        Professor Fran Boyle AM becomes the Chair of the Board of Directors
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-3"
                
            >
                <VerticalTimelineYear
                    year="2011"
                >
                    <VerticalTimelineSection
                        modalContent={<TrialResultsContent2011 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        ANZ 0001 clinical trial results are published in the Journal of Clinical Oncology
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={AnzbctgLogo2011}
                        modalContent={<LogoContent2011 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                        modalContentClassName="pb-0"
                    >
                        Our name and logo are updated
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-4"
                
            >
                <VerticalTimelineYear
                    year="2010"
                >
                    <VerticalTimelineSection
                        image={SoozySmith2010}
                    >
                        Soozy Smith is appointed CEO of Breast Cancer Trials
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={NicholasWilcken2010}
                    >
                        Associate Professor Nicholas Wilcken becomes the Chair of the Scientific Advisory Committee
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={LeonieYoung2010}
                    >
                        Ms Leonie Young becomes Chair of the Consumer Advisory Panel
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-5"
                
            >
                <VerticalTimelineYear
                    year="2009"
                >
                    <VerticalTimelineSection>
                        13,851 women were diagnosed with breast cancer in Australia and 2,759 in New Zealand. 1 in 8 women were diagnosed by the age of 85. The five-year survival rate was 90%
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-1"
                
            >
                <VerticalTimelineYear
                    year="2008"
                >
                    <VerticalTimelineSection
                        modalContent={<TrialResultsContent2008 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        Results of the Breast International Group BIG 02-98 / IBCSG 20-98 clinical trial
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        The Breast Cancer Trials research program grew to include 80 institutions across Australia and New Zealand
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-2"
                
            >
                <VerticalTimelineYear
                    year="2007"
                >
                    <VerticalTimelineSection
                        image={ChrisBath2007}
                    >
                        Chris Bath becomes the Ambassador for  The Australian Women’s Health Diary
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-3"
                
            >
                <VerticalTimelineYear
                    year="2006"
                >
                    <VerticalTimelineSection
                        modalContent={<TrialResultContent2006 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        Long-term results of the IBIS-I clinical trial
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={FranBoyle2006}
                    >
                        Professor Fran Boyle AM  becomes the Chair of the Scientific Advisory Committee
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={ImpactAdvocateProgram2006}
                    >
                        The IMPACT Advocate Program is held at an Annual Scientific Meeting for the first time in Cairns
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-4"
                
            >
                <VerticalTimelineYear
                    year="2005"
                >
                    <VerticalTimelineSection
                        modalContent={<HeraContent2005 />}
                        modalClassName={"vertical-timeline-modal--color-4"}
                    >
                        Results of the HERA clinical trial
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={JacquieChirgwin2005}
                    >
                        Associate Professor Jacquie Chirgwin  becomes the Chair of  the Board of Directors
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-5"
                
            >
                <VerticalTimelineYear
                    year="2004"
                >
                    <VerticalTimelineSection
                        modalContent={<TreatmentContent2004 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        A successful new treatment approach for early breast cancer
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-1"
                
            >
                <VerticalTimelineYear
                    year="2003"
                >
                    <VerticalTimelineSection
                        modalContent={<GenomeContent2003 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        The human genome is sequenced
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        Participation in our clinical trials  research program reaches a milestone of 10,000 women
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-2"
                
            >
                <VerticalTimelineYear
                    year="2002"
                >
                    <VerticalTimelineSection
                        modalContent={<AromataseContent2002 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        A new class of drugs called aromatase inhibitors are introduced
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={ImpactWorkshop2002}
                        modalContent={<ImpactContent2002 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        The first IMPACT Workshop
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-3"
                
            >
                <VerticalTimelineYear
                    year="2000"
                >
                    <VerticalTimelineSection>
                        Four major sub-types of breast cancer are identified: Luminal A, Luminal B, HER2-positive and Basal-like (triple negative)
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<RgpContent2000 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        The Regular Giving Program is launched
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<MothersDayContent2000 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        The Annual Mother’s Day Research Appeal is launched
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        11,413 women were diagnosed with breast cancer in Australia and more than 2,306 in New Zealand. 1 in 8 women were diagnosed by the age of 85. The five-year survival rate was 87%
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-4"
                
            >
                <VerticalTimelineYear
                    year="1999"
                >
                    <VerticalTimelineSection
                        modalContent={<TrastuzumabContent1999 />}
                        modalClassName={"vertical-timeline-modal--color-4"}
                    >
                        Trastuzumab enters clinical practice for metastatic breast cancer
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={Awhd1999}
                    >
                        The first edition of The Australian Women’s Health Diary is produced
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={JenniferKeyte1999}
                    >
                        Jennifer Keyte becomes the Australian Women’s Health Diary Ambassador
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-5"
                
            >
                <VerticalTimelineYear
                    year="1998"
                >
                    <VerticalTimelineSection
                        modalContent={<CapContent1998 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                        image={ConsumerAdvisoryPanel1998}
                    >
                        The Consumer Advisory Panel is established 
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<ChemotherapyContent1998 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        Clinical trials research proves the benefits of neoadjuvant chemotherapy 
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={FirstNewsletter1998}
                        modalContent={<NewsletterContent1998 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        The first bi-annual Breast Cancer Trials Supporter Newsletter
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<BequestContent1998 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        The Bequest Program Commences
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        By 1998, there were 61 participating institutions across Australia and New Zealand contributing to the Breast Cancer Trials research program 
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-1"
                
            >
                <VerticalTimelineYear
                    year="1996"
                >
                    <VerticalTimelineSection>
                        Participation in our clinical trials  research program reaches a milestone of 5,000 women 
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<PartnershipContent1996 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                        image={AvonPartnership1996}
                    >
                        Our corporate partnership with Avon Australia and  New Zealand starts
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<BiopsyContent1996 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        The introduction of sentinel lymph node biopsy
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<RaceContent1996 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                        image={RaceForResearch1996}
                    >
                        First Race for Research
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<SupportersContent1996 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        First communication sent to potential supporters
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<GolfContent1996 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                        image={AnnualGolfDay1996}
                    >
                        Inaugural Annual Golf Day
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-2"
                
            >
                <VerticalTimelineYear
                    year="1995"
                >
                    <VerticalTimelineSection
                        image={CommonwealthBankPartnership1995}
                        modalContent={<PartnershipContent1995 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Our corporate partnership with the Commonwealth Bank starts
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={RaymondSnyder1995}
                    >
                        Associate Professor Raymond Snyder becomes the Chair of the Board  of Directors
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-3"
                
            >
                <VerticalTimelineYear
                    year="1994"
                >
                    <VerticalTimelineSection
                        modalContent={<IdentificationContent1994 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        Identification of the BRCA1 and BRCA2 genes
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<BciaContent1994 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        Our fundraising department, Breast Cancer Institute of Australia, is established
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        Taxanes are introduced for the treatment of metastatic breast cancer, after being shown to improve outcomes for patients with advanced breast cancer
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<ConferenceContent1994 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        Australia’s first National Breast Cancer Consensus Conference
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        9,760 women were diagnosed with breast cancer in Australia and 1,860 in New Zealand. 1 in 8 women were diagnosed with breast cancer by the age of 85. The five-year survival rate was 83%
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-4"
                
            >
                <VerticalTimelineYear
                    year="1992"
                >
                    <VerticalTimelineSection
                        modalContent={<IbisContent1992 />}
                        modalClassName={"vertical-timeline-modal--color-4"}
                    >
                        The IBIS-I clinical trial commences patient recruitment
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        image={ScientificAdvisoryCommittee1992}
                        modalContent={<SacContent1992 />}
                        modalClassName={"vertical-timeline-modal--color-4"}
                    >
                        Our Scientific Advisory Committee is established
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-5"
                
            >
                <VerticalTimelineYear
                    year="1991"
                >
                    <VerticalTimelineSection
                        modalContent={<BreastScreeningContent1991 />}
                        modalClassName={"vertical-timeline-modal--color-5"}
                    >
                        Free breast screening starts in Australia
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        BCT becomes incorporated and our first Chair of the Board is Dr Michael Byrne
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-1"
                
            >
                <VerticalTimelineYear
                    year="1988"
                >
                    <VerticalTimelineSection>
                        6,729 women were diagnosed with breast cancer in Australia and 1,454 in New Zealand. 1 in 10 women were diagnosed with breast cancer by the age of 85. The five-year survival rate was 76%
                    </VerticalTimelineSection>
                    <VerticalTimelineSection>
                        In its first ten years, Breast Cancer Trials more than doubled its number of participating institutions to include 30 across Australia and New Zealand
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-2"
                
            >
                <VerticalTimelineYear
                    year="1987"
                >
                    <VerticalTimelineSection
                        image={NewcastleRelocation1987}
                        modalContent={<RelocateContent1987 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        BCT relocates to Newcastle
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<TrialResultsContent1987 />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Results of the ANZ 8101 clinical trial are published in the New England  Journal of Medicine
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-3"
                
            >
                <VerticalTimelineYear
                    year="1984"
                >
                    <VerticalTimelineSection
                        modalContent={<IdentificationContent1984 />}
                        modalClassName={"vertical-timeline-modal--color-3"}
                    >
                        Identification of the HER2 gene
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-4"
                
            >
                <VerticalTimelineYear
                    year="1982"
                >
                    <VerticalTimelineSection>
                        5,311 women were diagnosed with breast cancer in Australia and 1,066 in New Zealand. 1 in 12 women were diagnosed with breast cancer by the age of 85. The five-year survival rate was 72%
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-5"
                
            >
                <VerticalTimelineYear
                    year="1979"
                >
                    <VerticalTimelineSection>
                        First BCT Annual Scientific Meeting was held at the Royal Prince Alfred Hospital
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-1"
                
            >
                <VerticalTimelineYear
                    year="1978"
                >
                    <VerticalTimelineSection
                        image={JohnForbes1978}
                        modalContent={<EstablishmentContent1978 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        Establishment of the Australian New Zealand Breast Cancer Trials Group (now Breast Cancer Trials)
                    </VerticalTimelineSection>
                    <VerticalTimelineSection
                        modalContent={<FirstTrialContent1978 />}
                        modalClassName={"vertical-timeline-modal--color-1"}
                    >
                        The first BCT trials called ANZ 7801/2 commence
                    </VerticalTimelineSection>
                        <VerticalTimelineSection>
                        Breast Cancer Trials was established with 14 collaborating institutions
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
            <VerticalTimelineElement
                className="vertical-timeline-element--color-2"
                
            >
                <VerticalTimelineYear
                    year="1970s"
                >
                    <VerticalTimelineSection
                        modalContent={<ExtractContent1970s />}
                        modalClassName={"vertical-timeline-modal--color-2"}
                    >
                        Extract from 'The Australian New Zealand Breast Cancer Trials Group: Some Contributions to Breast Cancer Trials'
                    </VerticalTimelineSection>
                </VerticalTimelineYear>
            </VerticalTimelineElement>
        </VerticalTimeline>
    );
}
export default Timeline;