import * as React from 'react';

import Loadable from 'react-loadable';
import Loading from '../../Loading';

export function loadAsync<T = any>(importFn: any, options?: { disableLoadingComponent?: boolean }) {
    return Loadable({
        loader: importFn,
        loading() {
            if (options && options.disableLoadingComponent) {
                return null;
            }

            //return null;
            return <Loading />
        }
    });
}