import * as React from 'react';

interface IResearchReportImpactContentProps {

}

class ResearchReportImpactContent extends React.PureComponent<IResearchReportImpactContentProps> {
    render() {
        return (
            <>
                <h4>
                    QUT’s Australian Centre for Philanthropy and Nonprofit Studies conducts an independent report into the impact of Breast Cancer Trials research
                </h4>
                <p>
                    QUT’s Australian Centre for Philanthropy and Nonprofit Studies (ACPNS), examined the impact of Breast Cancer Trials research, uncovering that the organisation has affected not just outcomes for breast cancer patients, but also delivered tangible and significant health and social benefits to the community, educated the public about clinical trials and the value of participating in this research, and empowered doctors in the treatment of their patients. To read more, click <a href="https://impact.breastcancertrials.org.au/" className="content-link-color-2">here</a>.
                </p>
            </>
        );
    }
}

export default ResearchReportImpactContent;