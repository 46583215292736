import * as React from 'react';

interface ILaunchNeoadjuvantContentProps {

}

class LaunchNeoadjuvantContent extends React.PureComponent<ILaunchNeoadjuvantContentProps> {
    render() {
        return (
            <>
                <h4>
                    Launch of Neoadjuvant Patient Decision Aid
                </h4>
                <p>
                    For many women recently diagnosed with breast cancer, the treatment process can be overwhelming and stressful. In certain situations, doctors may offer treatment with chemotherapy or hormonal therapy before surgery to the breast and lymph nodes (neoadjuvant).
                </p>
                <p>
                    Breast Cancer Trials has launched the online <a href="https://www.breastcancertrials.org.au/breast-cancer-resources/neoadjuvant-patient-decision-aid/" className="content-link-color-3">Neoadjuvant Patient Decision Aid</a> to help women recently diagnosed with breast cancer decide if this is the right treatment choice for them. The information provided in the online decision aid is tailored to each breast cancer patient and factors in what type of breast cancer they have been diagnosed with. The decision aid is not intended to change a patient’s mind about their treatment, rather to present an evidence-based view of their options and ensure their treatment choice is aligned with their particular circumstance.
                </p>
            </>
        );
    }
}

export default LaunchNeoadjuvantContent;