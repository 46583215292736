import * as React from 'react';

interface IProspectResultsContentProps {

}

class ProspectResultsContent extends React.PureComponent<IProspectResultsContentProps> {
    render() {
        return (
            <>
                <h4>
                    Results of the PROSPECT clinical trial
                </h4>
                <p>
                    The primary results for the <a href='https://www.breastcancertrials.org.au/trials/prospect/' className="content-link-color-2">PROSPECT</a> clinical trial were <a href='https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(23)02476-5/abstract' className="content-link-color-2">published in The Lancet</a>, providing evidence for a new treatment approach that may benefit both patients and the health system alike.
                </p>
                <p>
                    The study examined whether a combination of MRI and pathology findings can identify women with early breast cancer who can safely avoid radiotherapy. It was conducted across four sites, including the Royal Melbourne Hospital (RMH), the Royal Women’s Hospital, the Austin Hospital in Melbourne and the Mater Hospital in Sydney. In addition, an economic analysis was undertaken to compare models of care.
                </p>
                <p>
                    The PROSPECT study has found that using MRI and pathology findings can identify women who have a very low risk of breast cancer recurrence and may be suitable for a de-escalation in radiotherapy treatment. The net monetary benefit of this treatment model was found to be $2,900 per patient.
                </p>
                <p>
                    In addition, research presented at the San Antonio Breast Cancer Conference has found that patients participating in the PROSPECT study who did not have radiotherapy, had substantially lower rates of fear of breast cancer recurrence and better quality of life compared to those who did. Researchers found that patients have a positive perception about tailored care, lower treatment burden and trust in clinicians.
                </p>
            </>
        );
    }
}

export default ProspectResultsContent;