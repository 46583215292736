import * as React from 'react';


import TimelineCircleImage from '../../../../timeline/TimelineCircleImage/TimelineCircleImage';

const Image = require('../../../../../assets/img/timeline/2022-Nicholas-Zdenkowski.jpg');

interface INicholasZdenkowskiContentProps {

}

class NicholasZdenkowskiContent extends React.PureComponent<INicholasZdenkowskiContentProps> {
    render() {
        return (
            <>
                <TimelineCircleImage image={Image} />
                <h4>
                	Dr Nicholas Zdenkowski is appointed Chair of the Scientific Advisory Committee
                </h4>
                <p>
                    Dr Nicholas Zdenkowski is a Medical Oncologist with a strong interest in breast cancer clinical practice and research. He is Visiting Medical Officer at Maitland Private Hospital and Lake Macquarie Private Hospital, and is Conjoint Senior Lecturer in the Faculty of Medicine at the University of Newcastle.
                </p>
                <p>
                    Nick first became a member of BCT in 2010 and his role in clinical trials has included Chief Investigator, Study Chair, Co-Investigator, Steering Committee Member and external reviewer. In 2018 he became BCT’s Medical Advisor, assisting with the genesis of trials through to reporting of results.
                </p>
            </>
        );
    }
}

export default NicholasZdenkowskiContent;