import * as React from 'react';

interface INeonResultsContentProps {

}

class NeonResultsContent extends React.PureComponent<INeonResultsContentProps> {
    render() {
        return (
            <>
                <h4>
                    Results of the Neo-N clinical trial
                </h4>
                <p>
                    Results from the Neo-N clinical trial have shown that over half of early-stage triple negative breast cancer patients involved in the immunotherapy study, had no evidence of cancer in the breast and lymph nodes at surgery following treatment.
                </p>
                <p>
                    The primary results of the <a href='https://www.breastcancertrials.org.au/trials/neo-n-2/' className="content-link-color-1">Neo-N</a> trial were announced as a late breaking abstract at the San Antonio Breast Cancer Symposium in the United States. The trial was developed by Australian researchers and conducted by the largest independent oncology clinical trials research group in Australia and New Zealand – Breast Cancer Trials. 108 women participated in the study at 14 institutions in Australia, New Zealand and Italy.
                </p>
                <p>
                    Neo-N investigated whether using an immunotherapy drug together with chemotherapy, is safe and effective in treating breast cancer before surgery in women and men with early triple negative breast cancer.
                </p>
                <p>
                    Researchers examined if adding in immunotherapy could compensate for giving less chemotherapy. Patients received treatment before their operation, with the goal that this treatment combination could give the same outcomes but with less short and long-term side effects.
                </p>
                <p>
                    The study found that patients with ‘immunotherapy sensitive’ cancer, as indicated by either tumour infiltrating lymphocytes, or PD-L1 positivity, had particularly high pathological complete responses (67% and 71% respectively).
                </p>
            </>
        );
    }
}

export default NeonResultsContent;