import * as React from 'react';
import classNames from 'classnames';

import './TimelineSection.scss'

import TimelineModal, { TimelineModalSize } from '../TimelineModal/TimelineModal';
import { FC } from 'react';
import { Box, Grid, Theme } from '@mui/material';
import TimelineAccordion from '../TimelineAccordion/TimelineAccordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from '../../../styles/makeStyles';

interface ITimelineSectionProps {
    image?: any;
    modalContent?: React.ReactNode;
    modalClassName?: string;
    modalContentClassName?: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
    rotate: {
        WebkitTransition: 'all 0.3s',
        transition: 'all 0.3s',
        transform: 'rotate(-180deg)'
    },
    standard: {
        WebkitTransition: 'all 0.3s',
        transition: 'all 0.3s',
        transform: 'rotate(0deg)'
    }
}));

const TimelineSection: FC<ITimelineSectionProps> = ({image, modalContent, modalClassName, modalContentClassName, children}) => {

    const [modal, setModal] = React.useState<boolean>(false);

    const {classes} = useStyles();

    const handleItemClick = () => {
        setModal(!modal);
    }

    const renderImageLayout = () => {
        return (
            <div className="row vertical-timeline-row">
                <div className="col-3" style={{width: 125}}>
                    <div className="vertical-timeline-image">
                        <img src={image} />
                    </div>
                </div>
                <div className="col">
                    {children}
                </div>
            </div>
        );
    }

    const renderNormalLayout = () => {
        return children;
    }

    const handleClose = () => {
        setModal(false);
    }

    return (
        <>
        {
            modalContent ? 
                <TimelineAccordion
                    modalContent={modalContent}
                    contentClassName={modalClassName} 
                    className={classNames("vertical-timeline-year-item")}
                >
                    <Box 
                        onClick={handleItemClick}
                        sx={{
                            width: '100%'
                        }}    
                    >
                        <Grid
                            container
                            spacing={4}
                            sx={{
                                p: 1,
                                mb: 0,
                                width: '100%',
                                ml:{
                                    xs: -2,
                                    md: -4
                                }
                            }}
                            className="vertical-timeline-element"
                        >
                            {
                                image && (
                                    <Grid 
                                        item
                                        xs={4}
                                        md={2}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div className="vertical-timeline-image">
                                            <img src={image} width="100%" />
                                        </div>
                                    </Grid>
                                )
                            }
                            <Grid 
                                item
                                xs={image ? 7 : 11}
                                md={image ? 9 : 11}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col">
                                    {children}
                                </div>
                            </Grid>
                            {
                                modalContent && (
                                    <Grid 
                                        item
                                        xs={1}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            pl:{
                                                xs: '0 !important',
                                                md: '32 !important'
                                            },
                                            justifyContent: 'end'
                                        }}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faChevronDown} 
                                            className={modal ? classes.rotate : classes.standard}
                                        />
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Box>
                </TimelineAccordion>
            : 
                <Box 
                    className={classNames("vertical-timeline-year-item")}
                    sx={{
                        p: 1
                    }}
                >
                    <Grid
                            container
                            spacing={4}
                            sx={{
                                p: '4px',
                                mb: 0,
                                width: '100%',
                                ml:{
                                    xs: -2,
                                    md: -4
                                }
                            }}
                            className="vertical-timeline-element"
                        >
                            {
                                image && (
                                    <Grid 
                                        item
                                        xs={4}
                                        md={2}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div className="vertical-timeline-image">
                                            <img src={image} width="100%" />
                                        </div>
                                    </Grid>
                                )
                            }
                            <Grid 
                                item
                                xs={image ? 8 : 12}
                                md={image ? 10 : 12}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div className="col">
                                    {children}
                                </div>
                            </Grid>
                        </Grid>
                </Box>
        }
        </>
    );
}

export default TimelineSection;