import React, { useEffect } from 'react';
import './App.scss';
import { Timeline } from './components/contents';

const WEBSITE = "https://bctrialsstg.wpengine.com/about/timeline/";

function App() {

	// Used to redirect users to wordpress site timeline page if they navigate directly to timeline page
	useEffect(() => {
		if(window.location === window.parent.location){
			window.location.href = WEBSITE;
		}
	}, [])

	return (
		<Timeline />
	);
}

export default App;
