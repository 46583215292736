import * as React from 'react';

interface IResultsOlympiaContentProps {

}

class ResultsOlympiaContent extends React.PureComponent<IResultsOlympiaContentProps> {
    render() {
        return (
            <>
                <h4>
                    Results from the OlympiA clinical trial
                </h4>
                <p>
                    The results of an overall survival analysis of the global <a href="https://www.breastcancertrials.org.au/trials/olympia/" className="content-link-color-3">OlympiA</a> study was presented at a virtual plenary session of the European Society of Medical Oncology, showing olaparib reduces deaths by 32% for breast cancer patients with an inherited BRCA1 or BRCA2 gene abnormality.
                </p>
                <p>
                    The trial was coordinated internationally by the Breast International Group and recruited 1,836 patients worldwide, including 60 women from 12 participating institutions throughout Australia.
                </p>
                <p>
                    This was a phase III clinical trial, which tested the efficacy and safety of olaparib tablets versus a placebo. OlympiA found that giving olaparib tablets twice daily for a year to patients with <a href='https://www.breastcancertrials.org.au/olaparib-increases-survival-for-early-breast-cancer-patients-with-brca1-2-gene-mutations/' className="content-link-color-3">BRCA1 and BRCA2 mutations</a>, after they have completed standard chemotherapy, improves overall survival rates.
                </p>
                <p>
                    The study also showed that overall quality of life was not adversely affected by olaparib compared with the placebo, so it is a generally well-tolerated treatment despite being a 12-month course.
                </p>
            </>
        );
    }
}

export default ResultsOlympiaContent;