import * as React from 'react';

import './Timeline.scss';

interface ITimelineProps {

}

class Timeline extends React.PureComponent<ITimelineProps> {
    render() {
        return (
            <div className="vertical-timeline">
                {this.props.children}
            </div>
        );
    }
}

export default Timeline;