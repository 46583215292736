import { Accordion, AccordionDetails, AccordionSummary, Theme } from '@mui/material';
import * as React from 'react';
import { FC } from 'react';
import * as ReactDom from 'react-dom';
import './TimelineAccordion.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { makeStyles } from "../../../styles/makeStyles";


const classNames =  require("classnames");

export enum TimelineAccordionSize {
    Sm = 1,
    Md = 2,
    Lg = 3,
    Xl = 4
}

interface ITimelineAccordionProps {
    open?: boolean;
    modalContent?: React.ReactNode;
    contentClassName?: string;
    className?: string;
}

const AccordionContainerIdDefault: string = 'timeline-Accordion';
const AccordionBodyClass: string = 'Accordion-open'
const AccordionBodyClassRegex: RegExp = new RegExp("(^|\s)" + AccordionBodyClass + "($|(?=(\s)))", "g");

const resize = () => {
    var root = document.getElementById('timeline-root');
    window.parent.postMessage(root?.offsetHeight, "*");
}

const queueResize = () => {
    return setTimeout(resize, 200);
}

const TimelineAccordion: FC<ITimelineAccordionProps> = ({modalContent, children, contentClassName, className}) => {
   
    return (
        <Accordion
            className={className}
            disableGutters
            elevation={0}
            onChange={queueResize}
        >
            <AccordionSummary
                className="summary"
                sx={{
                    m: 0,
                    p: 0
                }}
            >
                {children}
            </AccordionSummary>
            <AccordionDetails
                className={contentClassName}
                sx={{
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    m: 2,
                    p: 1
                }}
            >
                {modalContent}
            </AccordionDetails>
        </Accordion>
    );
}
export default TimelineAccordion;