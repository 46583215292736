import * as React from 'react';
import YouTube from 'react-youtube';

import './TimelineVideo.scss';

const classNames = require('classnames');

interface ITimelineVideoProps {
    videoId: string;
    imagePreview: any;
    autoPlay?: boolean;
}

interface ITimelineVideoState {
    videoPlaying: boolean;
    videoStarting: boolean;
    videoReady: boolean;
}

class TimelineVideo extends React.Component<ITimelineVideoProps, ITimelineVideoState> {
    player: any;

    constructor(props: ITimelineVideoProps) {
        super(props);

        this.state = { videoPlaying: false, videoReady: false, videoStarting: false };

        this.handlePreviewClick = this.handlePreviewClick.bind(this);
        this.handleVideoPlay = this.handleVideoPlay.bind(this);
        this.handleVideoEnd = this.handleVideoEnd.bind(this);
        this.handlePlayerReady = this.handlePlayerReady.bind(this);
        this.setVideoPlaying = this.setVideoPlaying.bind(this);
        this.setVideoStarting = this.setVideoStarting.bind(this);
        this.setVideoReady = this.setVideoReady.bind(this);
    }

    render() {
        const { imagePreview } = this.props;
        const { videoPlaying, videoReady, videoStarting } = this.state;

        return (
            <div className={classNames("vertical-timeline-video", { "video-loading": !videoReady }, { "video-playing": videoPlaying }, { "video-starting": videoStarting })}>
                {
                    //this.renderPreview()
                }
                {
                    this.renderVideo()
                }
            </div>
        );
    }

    handlePreviewClick() {
        if (this.player && this.player.playVideo) {
            this.player.playVideo()
        }

        this.setVideoStarting(true);
    }

    handlePlayerReady(event: any) {
        const { autoPlay } = this.props;

        this.player = event.target;

        if (this.player && this.player.playVideo && autoPlay) {
            this.player.playVideo()
        }

        this.setVideoReady(true);
    }

    handleVideoPlay() {
        this.setVideoPlaying(true);
        this.setVideoStarting(false);
    }

    handleVideoEnd() {
        this.setVideoPlaying(false);
    }

    setVideoStarting(starting: boolean) {
        this.setState({ videoStarting: starting });
    }

    setVideoReady(ready: boolean) {
        this.setState({ videoReady: ready });
    }

    setVideoPlaying(playing: boolean) {
        this.setState({ videoPlaying: playing });
    }

    renderPreview() {
        const { imagePreview } = this.props;

        const { videoReady } = this.state;

        return (
            <div className={classNames("vertical-timeline-video-preview")} onClick={ videoReady ? this.handlePreviewClick : undefined}>
                <img src={imagePreview} />
            </div>
        );
    }

    renderVideo() {
        const { videoId, autoPlay, imagePreview } = this.props;
        const { videoReady } = this.state;

        return (

            <YouTube
                videoId={videoId}
                opts={{
                    playerVars: {
                        rel: 0,
                        autoplay: autoPlay ? 1 : 0,
                        origin: window.location.origin
                    }
                }}
                onPlay={this.handleVideoPlay}
                onEnd={this.handleVideoEnd}
                onReady={this.handlePlayerReady}
            />
        );
    }
}

export default TimelineVideo;